<template>
  <el-menu :default-active="$route.path" router mode="horizontal">
    <!-- <el-menu-item index="/">PencilX Studio</el-menu-item> -->
    <el-menu-item index="/">首页</el-menu-item>
    <el-menu-item index="/about">关于</el-menu-item>
    <el-menu-item index="/job">招聘</el-menu-item>
    <el-menu-item index="/donate">捐赠</el-menu-item>
    <el-sub-menu>
      <template #title>产品与服务</template>
      <el-menu-item>
        <el-link
          :underline="false"
          href="https://bbs.pencilx.ml/"
          target="_blank"
        >
          论坛
        </el-link>
      </el-menu-item>
      <el-menu-item>
        <el-link
          :underline="false"
          href="http://mc.pencilx.ml/"
          target="_blank"
        >
          Minecraft服务器
        </el-link>
      </el-menu-item>
    </el-sub-menu>
  </el-menu>
  <el-dialog
    v-model="dialogVisible"
    width="400px"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <Auth :activeName="activeName" />
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: false,
      activeName: '',
    };
  },
  methods: {
    openDialog(activeName) {
      this.activeName = activeName;
      this.dialogVisible = true;
    },
  },
};
</script>
