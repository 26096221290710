<template>
  <div>
    <div v-for="links in footerLinks" :key="links.name" class="links">
      <p style="text-align: left">{{ links.name }}</p>
      <div>
        <p v-for="item in links.content" :key="item.name" class="link">
          <el-link
            :underline="false"
            :href="item.link"
            target="_blank"
            style="font-size: 16px"
          >
            {{ item.name }}
          </el-link>
        </p>
      </div>
      <div class="sep"></div>
    </div>
  </div>
  <p>© 2022 PencilX</p>
  <div class="placeholder"></div>
</template>

<script>
import List from '@/components/utils/List.vue';

export default {
  components: {
    List,
  },
  data() {
    return {
      footerLinks: [
        {
          name: '联系我们',
          content: [
            { name: '招聘', link: '/job' },
            { name: '合作', link: '' },
          ],
        },
        {
          name: '更多',
          content: [{ name: '捐赠', link: '/donate' }],
        },
      ],
    };
  },
};
</script>

<style scoped>
.el-footer {
  text-align: center;
}

.sep {
  display: none;
  height: 10px;
}

.placeholder {
  height: 20px;
}

.links {
  margin: 0 60px;
  display: inline-block;
  vertical-align: top;
}

.link {
  margin: 5px 0;
  text-align: left;
}
</style>
