import { createRouter, createWebHistory } from 'vue-router';

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/HomeView.vue'),
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('@/views/AboutView.vue'),
    meta: {
      title: '关于',
    },
  },
  {
    path: '/job',
    name: 'job',
    component: () => import('@/views/JobView.vue'),
    meta: {
      title: '招聘',
    },
  },
  {
    path: '/donate',
    name: 'donate',
    component: () => import('@/views/DonateView.vue'),
    meta: {
      title: '捐赠',
    },
  },
  {
    path: '/404',
    name: '404',
    component: () => import('@/views/NotFoundView.vue'),
  },
  {
    path: '/:catchAll(.*)',
    redirect: {
      name: '404',
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = `${to.meta.title} | PencilX 官网`;
  } else {
    document.title = 'PencilX 官网';
  }
  next();
});

export default router;
