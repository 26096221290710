<template>
  <el-container>
    <el-header>
      <PageHeader />
    </el-header>

    <el-main>
      <router-view />
      <el-backtop />
    </el-main>

    <el-footer>
      <el-divider />
      <PageFooter />
    </el-footer>
  </el-container>
</template>

<script>
import PageHeader from '@/components/root/PageHeader.vue';
import PageFooter from '@/components/root/PageFooter.vue';

export default {
  components: {
    PageHeader,
    PageFooter,
  },
};
</script>

<style>
html,
body {
  margin: 0;
  padding: 0;
  min-width: 1200px;
}
.el-main {
  margin: 0 auto;
  width: 1200px;
}

.el-footer {
  text-align: center;
}
</style>
